.header-container {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    gap: 8px; /* Espaciado entre el texto y el botón */
  }
  
  table.table > thead.table-dark > tr > th {
    text-align: center;
    vertical-align: middle;
  }

  table.table > tbody > tr > td {
    vertical-align: middle;
  }

  table.table > tbody > tr > td.text-small {
    font-size:  0.96em;
  }
  